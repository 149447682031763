import React from "react";
import { WEBSITE_URL } from "../../../../src/configs/website";
import { Link, useNavigate } from "react-router-dom";
import Ink from "react-ink";
// import PWAInstallation from "../PWAInstallation";

const Nav = (props) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/search-location");
  };

  const getUserSetAddress = () => {
    const address = localStorage.getItem("userSetAddress");
    if (address) {
      try {
        return JSON.parse(address);
      } catch (error) {
        console.error("Failed to parse userSetAddress:", error);
      }
    }
    return null;
  };

  const userSetAddress = getUserSetAddress();

  const getDisplayAddress = () => {
    if (!userSetAddress) return null;

    const { businessLocation, tag, house, address } = userSetAddress;

    if (businessLocation) {
      return (
        <span>  
        <strong className="text-uppercase mx-2 align-top">  {address}</strong>
        <svg
          width="14"
          height="20"
          viewBox="0 0 14 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.89429 18.3846H12.0643"
            stroke="#282C3F"
            stroke-opacity="0.9"
            stroke-width="1.7"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M6.98989 1C3.9613 1 1.5 3.45532 1.5 6.47656C1.5 10.584 6.98989 15.2057 6.98989 15.2057C6.98989 15.2057 12.4798 10.584 12.4798 6.47656C12.4798 3.45532 10.0185 1 6.98989 1Z"
            stroke="#282C3F"
            stroke-opacity="0.9"
            stroke-width="1.7"
            stroke-linejoin="round"
          ></path>
          <path
            d="M6.98955 3.73492C8.36202 3.73492 9.48495 4.82084 9.48495 6.14807C9.48495 7.4753 8.3745 8.56121 6.98955 8.56121C5.61707 8.56121 4.49414 7.4753 4.49414 6.14807C4.49414 4.82084 5.61707 3.73492 6.98955 3.73492Z"
            fill={localStorage.getItem("storeColor") || "#FF5200"}
          ></path>
        </svg>

      </span>
      );
    } else {
      const tagElement = tag ? (
        <strong className="text-uppercase mx-2 align-top">{tag}</strong>
      ) : (
        <strong className="text-uppercase mx-2 align-top">
          {localStorage.getItem("tagDefaultTex")}
        </strong>
      );

      const houseText = house
        ? house.length > 20
          ? `${house.substring(0, 20)}...`
          : house
        : "";

      const addressText = address
        ? address.length > 25
          ? `${address.substring(0, 25)}...`
          : address
        : "";

      return (
        <>
          <span className="float-right">
          {tagElement}
            <svg
              width="14"
              height="20"
              viewBox="0 0 14 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.89429 18.3846H12.0643"
                stroke="#282C3F"
                stroke-opacity="0.9"
                stroke-width="1.7"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M6.98989 1C3.9613 1 1.5 3.45532 1.5 6.47656C1.5 10.584 6.98989 15.2057 6.98989 15.2057C6.98989 15.2057 12.4798 10.584 12.4798 6.47656C12.4798 3.45532 10.0185 1 6.98989 1Z"
                stroke="#282C3F"
                stroke-opacity="0.9"
                stroke-width="1.7"
                stroke-linejoin="round"
              ></path>
              <path
                d="M6.98955 3.73492C8.36202 3.73492 9.48495 4.82084 9.48495 6.14807C9.48495 7.4753 8.3745 8.56121 6.98955 8.56121C5.61707 8.56121 4.49414 7.4753 4.49414 6.14807C4.49414 4.82084 5.61707 3.73492 6.98955 3.73492Z"
                fill={localStorage.getItem("storeColor") || "#FF5200"}
              ></path>
            </svg>

         
          </span>
          <br />
          <span className="float-right text-muted">
            {house ? <span>{houseText}</span> : <span>{addressText}</span>}
          </span>
        </>
      );
    }
  };

  return (
    <React.Fragment>
      <div className="col-12 p-0 py-1 sticky-top">
        <div className="block-content p-0 d-flex justify-content-between">
        <div className="my-1 mx-3">
            {props.logo && (
              <Link to={props.logoLink ? "/" : "#"}>
                <img
                  src={`${WEBSITE_URL}/assets/img/logos/${localStorage.getItem(
                    "storeLogo"
                  )}`}
                  alt={localStorage.getItem("storeName")}
                  className="store-logo "
                />
              </Link>
            )}
          </div>
          
          <button
            type="button"
            className="btn nav-location truncate-text"
            style={{
              position: "relative",
              maxWidth: window.innerWidth - 130,
            }}
            onClick={handleNavigation}
          >
            {getDisplayAddress()}

            <Ink duration="500" />
          </button>

          
        </div>
      </div>
    </React.Fragment>
  );
};

export default Nav;
