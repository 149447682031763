import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

let messaging;

const initializeFirebase = async () => {
  if ('serviceWorker' in navigator) {
    const firebaseConfig = {
    apiKey: "AIzaSyCor_0lNcJ08m_Ba4A3mTBxKzLG0AXUC1s",
  authDomain: "ohay-dev-test.firebaseapp.com",
  projectId: "ohay-dev-test",
  storageBucket: "ohay-dev-test.appspot.com",
  messagingSenderId: "409716753681",
  appId: "1:409716753681:web:ebd8eeca8a99ee652278b0",
  measurementId: "G-7LQ4YPPXGY"
    };

    const app = initializeApp(firebaseConfig);
    messaging = getMessaging(app);

    try {
      const currentToken = await getToken(messaging, { vapidKey: "BDsoFX6zYPb1m_VR9F1oj6wYajJbegIfapahVvVseVdoshnJl3p7xloe1MVxdTHiEuVO2dgvu6mJdl_" });
      if (currentToken) {
        console.log('FCM token:', currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    } catch (err) {
      console.log('An error occurred while retrieving token. ', err);
    }
  }
};

initializeFirebase();

export default messaging;
